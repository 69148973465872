/* eslint-disable max-len */
/* eslint-disable react/prop-types */

/* Plugins */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Image from 'gatsby-image';
import { graphql } from 'gatsby';

/* Components */
import {
  Container, Section, Article, PostView, SEO,
} from '../components/components';

const PostLayout = ({ data }) => {
  const { ...metaTags } = { ...data.datoCmsArticle.seoMetaTags.tags };

  const TEASER = data.datoCmsArticle.teaser;
  const SEO_TITLE = metaTags[1]?.attributes.content;
  const SEO_DESCRIPTION = metaTags[3]?.attributes.name === 'description' ? metaTags[3]?.attributes.content : TEASER;

  return (
    <>
      <SEO title={SEO_TITLE} description={SEO_DESCRIPTION} />
      <Container subPage>
        <Section article>
          <Article>
            <PostView
              title={data.datoCmsArticle.title}
              featureImage={data.datoCmsArticle.featureImage.fixed}
              author={data.datoCmsArticle.author}
              children={data.datoCmsArticle.articleContent.map((item) => {
                const itemKey = Object.keys(item)[0];
                switch (itemKey) {
                  case 'headingContent':
                    return <Markdown key={item.id} rehypePlugins={[rehypeRaw]} children={item[itemKey]} />;
                  case 'paragraphContent':
                    return <Markdown key={item.id} rehypePlugins={[rehypeRaw]} children={item[itemKey]} />;
                  case 'imageData':
                    return <Image key={item.id} fixed={item[itemKey].fixed} />;
                  default:
                    return null;
                }
              })}
            />
          </Article>
        </Section>
      </Container>
    </>
  );
};

export const query = graphql`
  query querySingleArticle($id: String!) {
    datoCmsArticle(id: { eq: $id }) {
      title
      teaser
      seoMetaTags {
        tags
      }
      featureImage {
        fixed {
          ...GatsbyDatoCmsFixed
        }
      }
      author
      articleContent {
        ... on DatoCmsHeading {
          headingContent
          id
        }
        ... on DatoCmsParagraph {
          paragraphContent
          id
        }
        ... on DatoCmsArticleImage {
          imageData {
            fixed {
              ...GatsbyDatoCmsFixed
            }
          }
          id
        }
      }
    }
  }
`;

export default PostLayout;
